import React, {useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import OperationLine from '../../models/OperationLine'
import CollaboratorStretch from '../../models/CollaboratorStretch'
import {getAllData, getChannels} from '../../Request/Request'
import {CardStretch, CardCollaboratorStretch, CardPacket} from '../Stretch/CardStretch'
import {FooterView} from '../../components/FooterView'
import {HeaderView} from 'components/HeaderView';
import {getCart} from 'Modules/Cart/Request';
import {Spinner} from 'reactstrap';
import {modalError} from 'Modules/Payment/Request';
import AllData from 'models/AllData';
import {deepFreeze} from 'Modules/Util';
import * as Sentry from "@sentry/react";
import {useInitIdioma} from 'customHooks/useInitIdioma';
import {conditionalAdd, isValidWithChannels} from './Util';
import {BodyError} from 'components/bodyError';
import queryString from 'query-string'
import MainView from './containers/MainView';
import Destination from '../Destination/Destination'
import Category from '../Category/Category'
import Tag from '../Tag/Tag'
import {IItem} from './helpers/IItem'
import {getMainItems, typePriceShow} from './helpers/getMainItems'
import {useAfiliate} from 'hooks/useAfiliate'
import {MarketingC} from '../Marketing/MarketingC'
import {useMarketing} from '../../hooks/useMarketing'
import {generateUrl} from '../../helpers/slug/generateUrl'
import {TypeTicket} from '../../models/TypetTicket'
import {useScript} from '../../hooks/useScript'

interface MainPageParams {
  public?: string;
}

type MainPageProps = RouteComponentProps<MainPageParams>;

interface Props extends MainPageProps {
  social?: boolean
}

export const MainPage: React.FC<Props> = (props: Props) => {

  const publicToken = props.match.params.public;
  const history = useHistory();
  const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook
  // const [businessUnit, setBusinessUnit] = useState<BusinessUnit>();
  const [operationLines, setOperationLines] = useState<OperationLine[]>();
  const [collaboratorStretches, setCollaboratorStretches] = useState<CollaboratorStretch[]>();
  const [loaded, setLoaded] = useState<boolean>(false)
  const [noError, setnoError] = useState<boolean>(true);
  const [cart, setCart] = useState<any>();
  const [channels, setChannels] = useState<any>(undefined);

  const [busqueda, setBusqueda] = useState<string>('');
  const [data, setData] = useState<AllData>(undefined);
  const [dataOriginal, setDataOriginal] = useState<any>(undefined);
  const [ninguno, setNinguno] = useState<boolean>(false)
  const [screenWidth, setScreenWidth] = useState<any>(undefined)
  const [monedasGreenpay, setMonedasGreenpay] = useState<any>();
  const [currencyCredomatic, setCurrencyCredomatic] = useState<any>()

  const [destinationSelected, setDestinationSelected] = useState<string[]>([])
  const [categorySelected, setCategorySelected] = useState<string[]>([])
  const [tagSelected, setTagSelected] = useState<string[]>([])
  // const [tagSelected, setTagSelected] = useState<number[]>([5, 4])

  const destinations = useRef<Destination[]>([])
  const categories = useRef<Category[]>([])
  const tags = useRef<Tag[]>([])

  // const [a, setA] = useState<any>()
  //let dataOriginal = undefined
  const parsed = queryString.parse(window.location.search)
  const at = parsed?.at

  const {afiliate: a, error: errorAfiliate} = useAfiliate(publicToken, at)

  const {marketing, error: errorMarketing} = useMarketing(publicToken)

  const favicon = (at? at.hrefFavicon : marketing?.favicon) || '/logo_ticando.png'
  
  // console.log('favicon: ', favicon)
  useScript(favicon, 'link', {
    type: 'image/x-icon',
    rel: 'icon',
  })
  
  useEffect(() => {
    if (errorAfiliate || errorMarketing) {
      console.log('MainPage - error en afiliate: ', errorAfiliate)
      console.log('MainPage - error en marketing: ', errorMarketing)
      modalError(
        errorAfiliate.code || errorMarketing.code,
        'Contacte con el administrador de la página. Gracias y disculpe las molestias',
        () => {
          // history.push(`/${publicToken}/error/${reason.code}?at=${parsed.at}`)
        }
      )
    }
  }, [errorAfiliate, errorMarketing])

  useEffect(() => {
    if (!screenWidth) {
      setScreenWidth(window.screen.width)
    } else if (screenWidth !== window.screen.width) {
      setScreenWidth(window.screen.width)
    }
  }, [screenWidth])

  useEffect(() => {
    i18n.changeLanguage(idioma);

    if (loaded) {
      return
    }

    if (!channels) {
      getChannels(publicToken).then(canales => {
        if (canales.msg === 'No TMT') {
          setChannels({msg: 'notmt. Code: 48'})
          if (canales.currency) setCurrencyCredomatic(canales.currency)
        } else if (canales.msg === 'GREENPAY') {
          setMonedasGreenpay(canales.monedasDisponibles)
          setChannels({msg: 'greenpay. Code: 49'})
        } else if (canales.msg === 'No credenciales') {
          setnoError(false)
          setChannels({msg: 'No credenciales. Code: 50'})
        } else {
          setChannels(canales);
        }

        if (!data) {
          getAllData(publicToken, at)
            .then(res => {

              const _destinations = []
              const _categories = []
              const _tags = []

              res.operationLines.forEach(op => {
                op.stretches?.forEach(stretch => {
                  if (stretch.dataweb?.destination) {
                    if (!_destinations.find(value => value.name === stretch.dataweb.destination.name)) {
                      _destinations.push(stretch.dataweb.destination)
                    }
                  }

                  if (stretch.dataweb?.category) {
                    if (Array.isArray(stretch.dataweb.category)) {
                      for (const category of stretch.dataweb.category) {
                        if (!_categories.find(value => value.name === category.name)) {
                          _categories.push(category)
                        }
                      }
                    } else {
                      if (!_categories.find(value => value.name === stretch.dataweb.category.name)) {
                        _categories.push(stretch.dataweb.category)
                      }
                    }
                  }

                  if (stretch.dataweb?.tag) {
                    stretch.dataweb.tag.forEach(t => {
                      if (!_tags.find(value => value.name === t.name)) {
                        _tags.push(t)
                      }
                    })
                  }
                })

                let newPackets = []
                op.packets.forEach(packet => {

                  // Restricciones en paquetes con servicios en colaboración
                  let deletePacket = false
                  packet.stretches.forEach(stretch => {
                    //if(!stretch.allowInPackage) deletePacket = true //TODO
                  })

                  packet.collaboratorStretches.forEach(cs => {
                    //if(!cs.allowInPackage) deletePacket = true
                    if (cs.money_id !== packet.money_id) deletePacket = true // Se restringe que la moneda del servicio en colaboración sea igual que la del paquete
                  })

                  if (!deletePacket) newPackets.push(packet)
                })
                op.packets = newPackets
              })

              res.collaboratorStretches.forEach(cs => {
                // destination
                if (cs.dataweb?.destination) {
                  if (!_destinations.find(value => value.name === cs.dataweb.destination.name)) {
                    _destinations.push(cs.dataweb.destination)
                  }
                }

                // categories (puede ser un elemento (principio) o un array de elementos )
                if (cs.dataweb?.category) {
                  if (Array.isArray(cs.dataweb.category)) {
                    for (const category of cs.dataweb.category) {
                      if (!_categories.find(value => value.name === category.name)) {
                        _categories.push(category)
                      }
                    }
                  } else {
                    if (!_categories.find(value => value.name === cs.dataweb.category.name)) {
                      _categories.push(cs.dataweb.category)
                    }
                  }

                }

                if (cs.dataweb?.tag) {
                  cs.dataweb.tag.forEach(t => {
                    if (!_tags.find(value => value.name === t.name)) {
                      _tags.push(t)
                    }
                  })
                }
              })

              destinations.current = _destinations
              categories.current = _categories
              tags.current = _tags

              setData(res)
              setDataOriginal(res);
              preprocesamiento(res, res, canales)
            }).catch(error => {
            Sentry.captureException(new Error("MainPage - Data"));
            // history.push(`/${publicToken}/error/Ha ocurrido un error - Data`)
            console.log('1er catch: error: ', error)
            modalError('Ha ocurrido un error. Data')

            // modalError('Ha ocurrido un error. Data')
            setnoError(false)
          })
        } else {
          preprocesamiento(dataOriginal, data, canales)
        }
      }).catch(error => {
        Sentry.captureException(new Error("MainPage - Channels"));
        history.push(`/${publicToken}/error/Ha ocurrido un error al hacer la petición - Timeout`)
        // modalError('Ha ocurrido un error. Channels')
        setnoError(false)
      })
    } else {
      if (!data) {
        getAllData(publicToken, at)
          .then(res => {
            setData(res)
            setDataOriginal(res);
            preprocesamiento(res, res, channels)
          }).catch(error => {
          Sentry.captureException(new Error("MainPage - Data"));
          // history.push(`/${publicToken}/error/Ha ocurrido un error - Data`)
          modalError('Ha ocurrido un error. Data')
          setnoError(false)
        })
      } else {
        preprocesamiento(dataOriginal, data, channels)
      }
    }
  }, [busqueda, a]);


  useEffect(() => {
    let uuid;
    try {
      uuid = localStorage.getItem('tokenTicket');
    } catch (e) {
      history.push(`/${publicToken}/cookies`)
    }

    if (uuid) {
      getCart(uuid, publicToken)
        .then((res) => {
          if (res.code && res.code === 433) {
            try {
              localStorage.removeItem('tokenTicket')
            } catch (e) {

            }
            window.location.reload();
          }
          if (!res.error) {
            setCart(res);
          }
        })
        .catch(reason => {
          Sentry.captureException(new Error(`MainPage - getCart error`));
          modalError('Ha ocurrido un error: ' + reason)
          setnoError(false)
        })
    }
  }, []);

  const preprocesamiento = (originalParam, data: AllData, canales) => {

    let original = deepFreeze(originalParam)

    let collaboratorPackets = [];
    let collaboratorStretches = [];
    let lines = [];

    if (original.statusCode === 401) {
      Sentry.captureException(new Error("MainPage - Cliente no encontrado"));
      history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
      return null
    }
    if (original.statusCode) {
      setnoError(false)
      Sentry.captureException(new Error("MainPage - Ha ocurrido un error"));
      history.push(`/${publicToken}/error/Ha ocurrido un error`)
      return null
    }

    if (canales && !canales.hasOwnProperty('msg')) { // TODO y tiene que ser distinto del mensaje que se ponga para greenpay
      let flag = false;

      original.operationLines.forEach((op) => {

        let stretches = []
        let packets = []

        op.stretches.forEach((s) => {
          flag = isValidWithChannels(canales, s)
          if (flag) {
            stretches = conditionalAdd(busqueda, stretches, s)
          }
        })

        op.packets.forEach((s) => {
          flag = isValidWithChannels(canales, s)
          if (flag) {
            packets = conditionalAdd(busqueda, packets, s)
          }
        })

        let opModificado = JSON.parse(JSON.stringify(data.operationLines.find(o => o.id === op.id)))
        opModificado.stretches = stretches  // Si esto no se pone, el original está bien pero no se filtra y si se pone el original cambia
        opModificado.packets = packets
        lines = [...lines, opModificado]
      })

      original.collaboratorStretches.forEach((cs) => {
        flag = isValidWithChannels(canales, cs)
        if (flag) {
          collaboratorStretches = conditionalAdd(busqueda, collaboratorStretches, cs)
        }
        // return collaboratorStretches;
      })

      original.collaboratorPackets.forEach((cs) => {
        flag = isValidWithChannels(canales, cs)
        if (flag) {
          collaboratorPackets = conditionalAdd(busqueda, collaboratorPackets, cs)
        }
        //return collaboratorPackets;
      })

    } else {
      original.operationLines.forEach((op) => {

        let stretches = []
        let packets = []

        op.stretches.forEach((s) => {
          stretches = conditionalAdd(busqueda, stretches, s)
        })

        op.packets.forEach((s) => {
          packets = conditionalAdd(busqueda, packets, s)
        })

        let opModificado = JSON.parse(JSON.stringify(data.operationLines.find(o => o.id === op.id)))
        opModificado.stretches = stretches  // Si esto no se pone, el original está bien pero no se filtra y si se pone el original cambia
        opModificado.packets = packets
        lines = [...lines, opModificado]
      })

      original.collaboratorStretches.forEach((cs) => {
        collaboratorStretches = conditionalAdd(busqueda, collaboratorStretches, cs)
      })

      original.collaboratorPackets.forEach((cs) => {
        collaboratorPackets = conditionalAdd(busqueda, collaboratorPackets, cs)
      })
      // collaboratorStretches = original.collaboratorStretches;
      // collaboratorPackets = original.collaboratorPackets;
    }

    // Compruebo si no todo lo filtrado es vacio
    let vacio = true;
    if (lines.length !== 0) {
      lines.forEach(l => {
        vacio = vacio && l.stretches.length === 0 && l.packets.length === 0
      })
    } else {
      original.operationLines.forEach(l => {
        vacio = vacio && l.stretches.length === 0 && l.packets.length === 0
      })
    }

    if (collaboratorStretches.length === 0 && collaboratorPackets.length === 0 && vacio) {
      setNinguno(true)
    } else {
      setNinguno(false)
    }

    setDataOriginal(original)
    if (lines.length !== 0) {
      setOperationLines(lines)
    } else {
      setOperationLines(original.operationLines || [])
    }

    setCollaboratorStretches(collaboratorStretches.sort((a, b) => {
      return a.id - b.id
    }))

    setLoaded(true)
  }

  // @ts-ignore
  if (noError && props.social) {

    const items: IItem[] = []

    if (loaded) {
      items.push(...getMainItems({
        operationLines,
        collaboratorStretches,
        t,
        typeShow: typePriceShow.orderLow,
        publicToken,
        at
      }))
    }
    /** Options **/
    const destinationOptions = destinations.current.map(item => ({
      name: item.name,
      value: item.name,
    }))

    const categoryOptions = categories.current.map(item => ({
      name: item.name,
      value: item.name,
    }))

    const tagOptions = tags.current.map(item => ({
      name: item.name,
      value: item.name,
    }))
    /**  *********  **/

    const filter = <P extends {
      title: string,
      stretch_id?: number,
      operator_id?: number
    }>(list: P[], fieldSelected: string[], field: string): P[] => {
      if (!list || !field) return []

      return list.reduce((prev: P[], current: P) => {
        // hay que distinguir si value es un valor único o un array
        if (Array.isArray(current[field])) {
          current[field].forEach((item) => {
            if (fieldSelected.find(fs => fs === item.name)) {
              // compruebo si está ya en previous; uso la propiedad title, stretch_id y operator_id
              if (!prev.find(pv => pv.title === current['title'] && pv.stretch_id === current['stretch_id'] && pv.operator_id === current['operator_id'])) {
                prev.push(current)
              }
            }
          })
        } else {
          if (fieldSelected.find(fs => {
            return fs === current[field]?.name
          })) {
            prev.push(current)
          }
        }

        return prev
      }, [])
    }

    let filtered: IItem[] = items

    if (destinationSelected.length > 0) {
      filtered = filter<IItem>(filtered, destinationSelected, 'destination')
    }

    if (categorySelected.length > 0) {
      filtered = filter<IItem>(filtered, categorySelected, 'category')
    }

    if (tagSelected.length > 0) {
      filtered = filter<IItem>(filtered, tagSelected, 'tag')
    }

    const handleOnBokingClick = (e, item) => {
      const options: any = {
        prefix: 'tour',
        destination: item.destinationName,
        category: item.categoryName,
        publicToken,
        stretch_id: item.stretch_id,
        operator_id: item.operator_id,
        withSlug: true,
        at,
        nameExperience: item.title
      }
      if (item.type === 'stretch') {
        options.type = TypeTicket.Stretch
        const url = generateUrl(options)
        history.push(url)
      } else if (item.type === 'cs') {
        options.type = TypeTicket.CollaboratorStretch
        const url = generateUrl(options)
        history.push(url)
      } else {
        throw new Error('item.type no valid')
      }
    }
    
    const hrefLogo = a?.hrefLogo
    const favicon = a && a.hrefFavicon? a.hrefFavicon : marketing?.favicon || ''
    const title = a ? a.name : marketing?.title || ''
    const urlCanonical = window.location.href
    
    return (
      <>
        <MarketingC
          /*favicon={favicon}*/
          title={title}
          marketing={marketing}
          urlCanonical={urlCanonical}
          /*description={marketing?.description}
          descriptionOG={marketing?.descriptionOG}
          imageOG={marketing?.imageOG}
          localeOG={marketing?.localeOG}
          siteNameOG={marketing?.siteNameOG}
          titleOG={marketing?.titleOG}*/
        />

        <MainView
          keyMaker={(item) => {
            let key
            if (item.type === 'cs') {
              key = `social_item_${item.title}_${item.stretch_id}_${item.operator_id}`
            } else {
              key = `social_item_${item.title}_${item.stretch_id}`
            }
            return key
          }}
          loading={!loaded}
          title={a ? a.name : data?.clientName}
          destinationValue={destinationSelected}
          destinationOptions={destinationOptions}
          categoryValue={categorySelected}
          categoryOptions={categoryOptions}
          tagValue={tagSelected}
          tagOptions={tagOptions}
          imgAlt={`${data?.clientName}`}
          imgUrl={hrefLogo || `https://res.cloudinary.com/marketingpyme/image/upload/civitrip/client_${data?.cloudinary_id}/logoCliente.jpg`}
          items={filtered}
          onChange={(e: any) => {
            switch (e.target.name) {
              case 'destination' :
                setDestinationSelected(e.target.value)
                break
              case 'category' :
                setCategorySelected(e.target.value)
                break
              case 'tag' :
                setTagSelected(e.target.value)
                break
            }
          }}
          onBookingClick={handleOnBokingClick}
          onImageClick={handleOnBokingClick}
          onIconClick={() => {
            at ?
              history.push(`/${publicToken}/cart?at=${at}`)
              :
              history.push({pathname: `/${publicToken}/cart`})
          }}
        />
      </>

    )
  }

  if (loaded && noError) {
    return (
      <div className="page-wrapper animated fadeIn">
        <HeaderView
          publicToken={publicToken}
          at={a?.publicToken}
          isMain={true}
          busqueda={busqueda}
          setBusqueda={setBusqueda}
          setLoaded={setLoaded}
          clientName={a ? a.name : data.clientName}
        />

        <section className="page-header">
          <div className="container" style={{
            display: screenWidth && screenWidth > 778 ? 'grid' : 'block',
            gridTemplateColumns: '470px 470px'
          }}>
            {/*servicios*/}
            {
              operationLines && operationLines.map((operationLine) => {
                return (
                  !operationLine.disabled_at &&
                  <React.Fragment key={'stretches_' + operationLine.id}>
                    {
                      operationLines && operationLine.stretches.map((stretch) => {
                        if (a && stretch.afiliatePrices && stretch.afiliatePrices.length === 0) return null

                        let imagen = 'https://res.cloudinary.com/marketingpyme/image/upload/v1639043362/civitrip/plugin/sinImagen.jpg'
                        if (stretch.dataweb && stretch.dataweb.image1) {
                          imagen = stretch.dataweb.image1.replace('http://', 'https://')
                        }

                        return (
                          !stretch.disableWeb && !stretch.disabled_at &&
                          <CardStretch
                            key={`stretch_${stretch.id}`}
                            publicToken={publicToken}
                            at={a?.publicToken}
                            stretch={stretch}
                            imagen={imagen}
                            id_operationLine={operationLine.id}
                            money={cart && !cart.message ? cart.money : null}
                            isGreenpay={channels && channels.hasOwnProperty('msg') && channels.msg.includes('greenpay')}
                            monedasGreenpay={monedasGreenpay}
                            t={t}
                          />
                        );
                      })
                    }
                  </React.Fragment>
                );
              })
            }

            {/*servicios en colaboración*/}
            {
              collaboratorStretches &&
              <>
                {
                  collaboratorStretches && collaboratorStretches.map((cStretch) => {
                    let imagen = 'https://res.cloudinary.com/marketingpyme/image/upload/v1639043362/civitrip/plugin/sinImagen.jpg'
                    if (cStretch.dataweb && cStretch.dataweb.image1) {
                      imagen = cStretch.dataweb.image1
                    }

                    return (
                      !cStretch.disableWeb && !cStretch.disabled_at && !cStretch.operationLine.disabled_at &&
                      <CardCollaboratorStretch
                        key={`${cStretch.id}_${cStretch.operator_id}`}
                        publicToken={publicToken}
                        at={a?.publicToken}
                        collaboratorStretch={cStretch}
                        image={imagen}
                        money={cart && !cart.message ? cart.money : null}
                        isGreenpay={channels && channels.hasOwnProperty('msg') && channels.msg.includes('greenpay')}
                        t={t}
                        monedasGreenpay={monedasGreenpay}
                      />
                    );
                  })
                }
              </>
            }

            {/*paquetes*/}
            {
              operationLines && operationLines.map((operationLine) => {
                if (!operationLines || !operationLine.packets || operationLine.disabled_at || operationLine.packets.length === 0) {
                  return null
                }

                return (
                  <React.Fragment key={'packets_' + operationLine.id}>
                    {
                      operationLines && operationLine.packets.map((packet) => {
                        let imagen = 'https://res.cloudinary.com/marketingpyme/image/upload/v1639043362/civitrip/plugin/sinImagen.jpg'
                        if (packet.dataweb && packet.dataweb.image1) {
                          imagen = packet.dataweb.image1
                        }
                        let stretches = [...packet.stretches, ...packet.collaboratorStretches]
                        let stretch_disabled = stretches.filter(s => s.disableWeb || s.disabled_at)
                        if (stretch_disabled.length !== 0) {
                          return null
                        }
                        return (
                          <CardPacket
                            key={`packet_${packet.id}`}
                            publicToken={publicToken}
                            image={imagen}
                            packet={packet}
                            isCollaborator={false}
                            money={cart && !cart.message ? cart.money : null}
                            t={t}
                            isGreenpay={channels && channels.hasOwnProperty('msg') && channels.msg.includes('greenpay')}
                            monedasGreenpay={monedasGreenpay}
                          />
                        );
                      })
                    }
                  </React.Fragment>
                );
              })
            }
            {
              ninguno
              && <h2 style={{textAlign: 'center', fontSize: 'xx-large'}}>Nada que mostrar</h2>
            }
          </div>
        </section>

        <FooterView/>

      </div>
    )
  } else if (noError) {
    return (
      <div className="page-wrapper animated fadeIn">
        <HeaderView publicToken={publicToken} at={a?.publicToken} isMain/>
        <div style={{top: '50%', left: '50%,', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
        </div>
        <FooterView/>
      </div>
    )
  } else if (!noError) {
    return (
      <div className="page-wrapper animated fadeIn">
        <HeaderView publicToken={publicToken} at={a?.publicToken}/>
        <BodyError
          message={channels && channels.hasOwnProperty('msg') && channels.msg.includes('50') ? 'Credenciales de la parasera erróneas. Code: 50' : "Error"}
          listParagraph={[t("contactAdmin"), t("thanksSorry")]}/>
        <FooterView/>
      </div>
    )
  }
}