import Price from '../../../models/Price'
import {priceWithTaxRounded} from './priceWithTaxRounded'
import {Tax} from '../../../models/Tax'

export const getAllPrices = ({prices, money, tax}: {prices: Price[], money: string, tax: Tax}) => {
  const result = []

  const ordered = [...prices].sort((a, b) => {
    return a.passengerCategory.order - b.passengerCategory.order
  })

  ordered.forEach(price => {
    const priceWithTax = priceWithTaxRounded(price.price, tax.value)
    const temp = {
      category: price.passengerCategory.name,
      price: `${priceWithTax} ${money}`
    }
    if (price.price > 0) {
      result.push(temp)
    }
  })


  return result
}