import {priceWithTaxRounded} from './priceWithTaxRounded'

export const minPrice = (tax) => (prev, current) => {
  if (current.price <= 0) {
    return prev
  }
  const price = priceWithTaxRounded(current.price, tax.value)
  // todo repasar si es correcto cuando no tiene precios o cuando sólo tiene precios anulados
  if ((current.price > 0 && prev.minPrice <= 0) || (prev.minPrice > current.price)) {
    return {minPrice: price, passengerCategory: current.passengerCategory.name}
  }

  return prev
}