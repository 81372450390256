import React from 'react'
import {useLocation} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import {Marketing} from '../../models/AllData'

interface IMarketingGProps {
  // images
  favicon?: string

  // content
  title?: string
  marketing: Marketing
  type?: string
  isOGG?: boolean
  urlCanonical?: string
  
  /*locale?: string
  localeOG?: string
  titleOG?: string
  description?: string
  descriptionOG?: string
  url?: string
  siteName?: string
  siteNameOG?: string
  image?: string
  imageOG?: string
  imageWidth?: number
  imageHeight?: number
  imageType?: string*/
}

export const MarketingC = (
  {
    /*locale, localeOG, titleOG, description, descriptionOG, url, siteName, siteNameOG, image, imageOG, imageWidth, imageHeight, imageType,*/
    marketing, favicon, type, title,  isOGG = false, urlCanonical
  }: IMarketingGProps) => {
  // console.log('favicon: ', favicon)

  const location = useLocation()
  
  if (!marketing) return null
  
  const ogUrl = window.location.origin + location.pathname
  const originSplited = window.location.origin.split('/')
  
  const {
    google_site_verificaton,
    googleSiteVerifications, locale, localeOG, titleOG, description, descriptionOG, siteName, siteNameOG, image, imageOG, imageWidth, imageHeight, imageType,
  } = marketing ?? {}
  
  const googleSV = googleSiteVerifications?.find(item => item.domain === originSplited[2])
  const _gsv = googleSV?.content || google_site_verificaton
    
  return (
    <>
      <Helmet>
        {urlCanonical && <link rel="canonical" href={urlCanonical}/>}
        {_gsv && <meta name="google-site-verification" content={_gsv}/>}
       {/* {favicon && <link rel="icon" type="image/x-icon" href={favicon}/>}*/}
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description}/>}
        {localeOG && <meta property="og:locale" content={`"${localeOG}"`}/>}
        <meta property="og:type" content={`"${type || "website"}"`}/>
        {titleOG && <meta property="og:title" content={`"${titleOG}"`}/>}
        {descriptionOG && <meta property="og:description" content={`"${descriptionOG}"`}/>}
        <meta property="og:url" content={`"${ogUrl}"`}/>
        {siteNameOG && <meta property="og:site_name" content={`"${siteNameOG}"`}/>}
        {imageOG && <meta property="og:image" content={`"${imageOG}"`}/>}
        {imageWidth && <meta property="og:image:width" content={`"${imageWidth}"`}/>}
        {imageHeight && <meta property="og:image:height" content={`"${imageHeight}"`}/>}
        {(imageWidth || imageHeight) && <meta property="og:image:type" content={`"${imageType || 'image/jpeg'}"`}/>}
      </Helmet>
    </>
  )
}