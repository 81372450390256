import {TypeTicket} from '../../models/TypetTicket'
import {convert} from './index'

export const generateUrl = (
  {
    prefix, destination, category, publicToken, stretch_id, operator_id, withSlug, at, type, nameExperience
  }) => {
  let result = ''
  let slug = ''
  
  if (withSlug) {
    slug = convert(nameExperience) + '/'
  }
  
  if (type === TypeTicket.Stretch) {
    result = `/${prefix}${destination? '/' + convert(destination) : ''}${category? '/' + convert(category) : ''}/${slug}${stretch_id}/${publicToken}`
    
  } else if (type === TypeTicket.CollaboratorStretch) {
    result = `/${prefix}${destination? '/' + convert(destination) : ''}${category? '/' + convert(category) : ''}/${slug}cs/${stretch_id}/${operator_id}/${publicToken}`
  } else {
    throw new Error('type not implemented.');
  }
  
  if (at) {
    result += `?at=${at}`
  }
  
  return result
}
