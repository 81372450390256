import React from 'react'
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import '@splidejs/react-splide/css'

const Carrousel = ({ items, ItemComponent, t, ariaLabel, title, keyMaker, onBookingClick, onImageClick }) => {
  const options = {
    type: "loop", // Loop back to the beginning when reaching the end
    autoScroll: {
      pauseOnHover: true, // Do not pause scrolling when hovering over the carousel
      pauseOnFocus: true, // Do not pause scrolling when the carousel is focused
      rewind: true, // Rewind to start when the end is reached
      speed: 1 // Scrolling speed
    },
    arrows: true, // Hide navigation arrows
    pagination: true, // Hide pagination dots
    fixedWidth: '300px', // Fixed width for each slide
    gap: '12px', // Gap between slides
  }
  
  return (
    <div>
      <div className=" max-w-screen-xl mx-auto relative z-20 overflow-x-hidden" style={{maxWidth: '1200px'}}>
        {title}
        <Splide
          options={options}
          extensions={{AutoScroll}} // Use the AutoScroll extension
        >
          {items.map((item, index) => (
            <SplideSlide>
              <ItemComponent
                keymaker={keyMaker}
                {...item}
                buttonText={t('bookNow')}
                onClick={(e) => onBookingClick(e, item)}
                onImageClick={(e) => onImageClick(e, item)}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  )
}

export default Carrousel

function generateSlides(length = 10, sig = 0): Array<{ src: string, alt: string }> {
  return Array.from({length}).map((value, index) => {
    index = sig || index;

    return {
      src: `https://source.unsplash.com/random/800x450?sig=${index}`,
      alt: `Image ${index + 1}`,
    };
  });
}

// Define the style for the images in the carousel
const imageStyle = {
  width: '447px',
  height: '664px',
  borderRadius: '20px',
  border: '1px solid #FFFFFF33',
};