import {priceWithTaxRounded} from './priceWithTaxRounded'

export const maxPrice = (tax) => (prev, current) => {
  if (current.price <= 0) {
    return prev
  }

  const price = priceWithTaxRounded(current.price, tax.value)

  if (!prev.maxPrice || prev.maxPrice < current.price) {
    return {maxPrice: price, passengerCategory: current.passengerCategory.name}
  }

  return prev
}