import {styled} from '@material-ui/styles'
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip'
import React from 'react'

export const HtmlTooltip = styled(({className, ...props}: TooltipProps) =>
  (
    <Tooltip {...props} classes={{popper: className}}/>
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'red',
      maxWidth: 500,
      fontSize: '15px',
      border: '1px solid #dadde9',
    },
  }
))