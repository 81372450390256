import {TypeDiscount, Vis} from '../models/Vis'
import Stretch from '../models/Stretch'
import CollaboratorStretch from '../models/CollaboratorStretch'
import {BasePacket} from '../models/Packet'
import Price from '../models/Price'
import {IDisabledDay} from '../models/IDisabledDay'
import {Line} from '../models/Line'
import Moment from 'moment'
import {IHora} from '../models/IHora'
import {TypePrice} from '../models/PassengerCategory'
import {DisabledDay} from '../helpers/DisabledDay'

export type AddCallBackType = (row: string, number: number, id: string, toolTip: string) => void;
export type RemoveCallBackType = (row: string, number: number, toolTip: null) => void;

const mountLines = (prices: Price[], visitas: Vis, taxValue: number, taxName: string, tax_id: number): Line[] => {
    const lines: Line[] = [];

    for(let i = 0; i < prices.length; i++){
        const precio = prices[i];
        const quantity = visitas.vis[i];
        const taxBase = (quantity * precio.price).toFixed(2);
        const taxAmount = (+taxBase * taxValue).toFixed(2);

        lines.push({
            price: precio.price,
            priceNet: precio.priceNet ? precio.priceNet : null,
            discount: 0,
            quantity: quantity,
            taxBase: +taxBase,
            taxAmount: +taxAmount,
            taxValue: taxValue,
            tax_id: tax_id,
            taxName: taxName,
            total: precio.passengerCategory.typePrice === TypePrice.byGroup ?
                Number((precio.price + (precio.price * taxValue)).toFixed(2)) :
                Number(((precio.price + (precio.price * taxValue)) * quantity).toFixed(2)),
            totalNet: precio.priceNet ? (precio.passengerCategory.typePrice === TypePrice.byGroup ?
                Number((precio.priceNet + (precio.priceNet * taxValue)).toFixed(2)) :
                Number(((precio.priceNet + (precio.priceNet * taxValue)) * quantity).toFixed(2))) : null,
            passengerCategory_id: precio.passengerCategory.id,
            passengerCategoryName: precio.passengerCategory.name,
        });
    }

    return lines;
}

const mountExtraLines = (extrasGlobal: any, visitas, extras: any) => {
    const extraLines: any = [];

    if(extrasGlobal) {
        for(let i = 0; i < extrasGlobal.length; i++){
            const precio = extrasGlobal[i].price;
            const quantity = visitas.extrasEdit.quantityExtras[i];
            const taxBase = (quantity * precio).toFixed(2);
            const taxValue = visitas.extrasEdit.taxes[i]
            const taxAmount = (+taxBase * taxValue).toFixed(2);
    
            if(quantity > 0) {
                extraLines.push({
                    price: +precio.toFixed(2),
                    extra_id: extrasGlobal[i].id,
                    extraName: visitas.extrasEdit.names[i],
                    description: extrasGlobal[i].description,
                    quantity: visitas.extrasEdit.quantityExtras[i],
                    taxBase: (quantity * precio).toFixed(2),
                    taxAmount: (+taxBase * taxValue).toFixed(2),
                    tax_id: extrasGlobal[i].tax_id,
                    taxName: extrasGlobal[i].taxName,
                    taxValue: extrasGlobal[i].tax.value,
                    total: Number(((precio + (precio * taxValue)) * quantity).toFixed(2)),
                });
            }
        }
    }

    return extraLines;
}

interface Props  {
    stretch: Stretch,
    afiliate?: {
        id: number
        name: string,
        email: string
    }
    selectedDate: string,
    selectedHour: IHora,
    visitas: Vis,
    lengthsName: 'stretch' | 'packet' | 'collaboratorStretch' | 'collaboratorPacket',
    publicToken: string,
    trayectoIdaVuelta: boolean,
    sentidoOrigen: boolean,
    prices: any,
    channels?
}

export const mountPayloadStretch = async (
    props: Props
) =>  {
    
    const {
        stretch, afiliate, selectedDate, selectedHour, visitas, lengthsName, publicToken, trayectoIdaVuelta, sentidoOrigen, prices, channels
    } = props
    
    const operationLine = stretch.operationLine
    const netAmount = +visitas.base.toFixed(2)
    const taxValue = +stretch.tax.value.toFixed(2)
    const qrTicket = operationLine.qrTicket + generateTokenticket(lengths[lengthsName] - operationLine.qrTicket.length)
    const taxAmount = netAmount * taxValue;
    const taxName = stretch.tax.name
    let total = +visitas.total.toFixed(2);
    const hasNet = ((lengthsName === 'collaboratorStretch' || afiliate) && visitas.totalNet)
    const totalNet = hasNet? 
      +visitas.totalNet.toFixed(2) 
      : 
      null;
    const _datebook = new Date(selectedDate)
    const datebook = stretch.tramoHora? 
      _datebook :
      new Date(Date.UTC(
        _datebook.getFullYear(),
        _datebook.getMonth(),
        _datebook.getDate(),
        0,
        0,
        0,
        0
      ))
    
    const datebookNTZ = new Date(Date.UTC(
      datebook.getFullYear(),
      datebook.getMonth(), 
      datebook.getDate(), 
      stretch.tramoHora ? selectedHour.hours : 0, 
      stretch.tramoHora ? selectedHour.minutes : 0, 
      0, 0
    ))
    let origin = stretch.origin
    let destination = stretch.destination
    
    if(!sentidoOrigen && sentidoOrigen !== null) {
        origin = stretch.destination
        destination = stretch.origin
    }
    
    let vuelta = stretch.vuelta ? trayectoIdaVuelta : false // Si el servicio tiene la opcion de ida y vuelta y solo ida, el valor de vuelta será el que esté seleccionada en ese momento

    if (stretch.tramoHora && selectedHour) {
        datebook.setHours(selectedHour.hours, selectedHour.minutes)
    }

    // Valor arbitrario si la pasarela no es TMT
    let channel = (channels.msg && (channels.msg.includes('notmt') || (channels.msg.includes('greenpay')))) ? 
      {id: 0} : 
      channels.find(c=>stretch.primaryMoney.alphabeticCode === c.currencies)

    if (visitas.discount) {
        total = +((visitas.total - visitas.totalDiscount).toFixed(2));
    }
    
    const payload: any = {
        ...visitas.discount? {
            totalDiscount: visitas.totalDiscount,
            typeDiscount: visitas.discount.typeDiscount,
            codeDiscount: visitas.discount.discountCode
        } : undefined,
        numero: qrTicket,
        datebook: datebook,
        datebookNTZ: datebookNTZ,
        hours: stretch.tramoHora ? selectedHour.hours : null,
        minutes: stretch.tramoHora ? selectedHour.minutes : null,
        year: _datebook.getFullYear(),
        month: _datebook.getMonth(),
        day: _datebook.getDate(),
        datecheck: Moment(new Date()).format(),
        user_id: 0,
        username: "widget",
        netAmount: netAmount,
        discount: visitas.discount? visitas.discount.value : 0,
        taxAmount: taxAmount,
        taxName: taxName,
        total: total,
        totalNet: hasNet ? totalNet : null,
        terminal: 'web',
        operationLine_id: operationLine.id,
        operationLineName: operationLine.name,
        businessUnitName: operationLine.businessUnit.name,
        tin: operationLine.businessUnit.tin,
        stretch_id: stretch.id,
        stretchName: stretch.name,
        vuelta: vuelta,
        printPrice: stretch.printPrice,
        hoponHopoff: stretch.hoponHopoff,
        tramoHora: stretch.tramoHora,
        timeExpiration: stretch.timeExpiration,
        timeAdvance: stretch.timeAdvance,
        tramoHoraVuelta: stretch.tramoHoraVuelta,
        origin: origin,
        destination: destination,
        timeDelay: stretch.timeDelay,
        timeUse: stretch.timeUse,
        aditionalInfo: stretch.aditionalInfo,
        lines: mountLines(prices, visitas, taxValue, taxName, stretch.tax_id),
        extraLines: mountExtraLines(stretch.extras, visitas, visitas.extrasEdit),
        money: stretch.primaryMoney.alphabeticCode,
        channel_id: channel.id,//stretch.primaryMoney.alphabeticCode === 'USD' ? 18498 : 13024,//channel.id,
        urlImage: stretch.dataweb ? stretch.dataweb.image1 : 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg', 
        afiliateName: afiliate?.name,
        afiliate_id: afiliate?.id,
        afiliateEmail: afiliate?.email,
        payments: [
            {
                method: 'card',
                afiliate_id: null,
                afiliateName: null,
                afiliateTypeCommission: null,
                afiliateCommission: null,
                amount: total, // sólo un pago
                receipt: null
            }
        ],
    }
    if (stretch.bookingFee) {
        const feeLine = {
            name: 'bookingFee',
            price: total,
            quantity: 1,
            taxBase: +(netAmount * stretch.bookingFee.value / 100).toFixed(2),
            taxAmount: +((total * stretch.bookingFee.value / 100) - (netAmount * stretch.bookingFee.value / 100)).toFixed(2),
            taxValue: stretch.tax.value,
            total: +(total * stretch.bookingFee.value / 100).toFixed(2),
            tax_id: stretch.tax.id
        }

        payload['feeLines'] = [feeLine]
        payload.total = payload.total + feeLine.total
        payload.payments = [
            {
                method: 'card',
                afiliate_id: null,
                afiliateName: null,
                afiliateTypeCommission: null,
                afiliateCommission: null,
                amount: payload.total, 
                receipt: null
            }
        ]
    }
    return payload;
}
export const addVisitanteGlobal = (
    visitas: Vis,
    trayecto: Stretch | CollaboratorStretch | BasePacket,
    index: number,
    prices: Price[]
) => {
    if(trayecto && index && visitas && prices) {
        const names = visitas.names;
        const extras = visitas.extras.slice();
        const price = prices[index];
        const precioBase = price.price;
        const precio = precioBase + precioBase * trayecto.tax.value;
        const precioNet = price.priceNet ?  (price.priceNet + (price.priceNet * trayecto.tax.value)) : undefined
        let auxVis = visitas.vis.slice();
        let auxPrice = visitas.price.slice();
        let auxPriceNet = visitas.priceNet ? visitas.priceNet.slice() : undefined;
        let base = visitas.base;
        let tot = visitas.total;
        let totDiscount = visitas.totalDiscount;
        let totNet = visitas.totalNet;
        let disabledButtonsAux = visitas.disabledButtons;
        const indices = visitas.indices

        const reducer = (accumulator, curr) => accumulator + curr;
        const pasajerosTotal = auxVis.reduce(reducer) + 1
        
        if (!(prices[index].passengerCategory.typePrice.localeCompare('byGroup') === 0 && visitas.vis[index] >= 1)) {
            if(trayecto.capacity !== undefined && trayecto.capacity !== null) {
                if (pasajerosTotal <= trayecto.capacity) {
                    auxPrice[index] += round(precio);
                    if (precioNet && auxPriceNet) {
                        auxPriceNet[index] += round(precioNet)
                    }
                    base += precioBase;
                    tot += precio;
                    if (precioNet) {
                        totNet += precioNet
                    }
                }
            } else {
                auxPrice[index] += round(precio);
                if (precioNet && auxPriceNet) {
                    auxPriceNet[index] += round(precioNet)
                }
                base += precioBase;
                tot += precio;
                if (precioNet) {
                    totNet += precioNet
                }
            }            
        } else (
            console.log('else')
        )

        if (!disabledButtonsAux) {
            disabledButtonsAux = true;
        }
        let limiteCapacidad = false;
        if(trayecto.capacity !== undefined && trayecto.capacity !== null) {
            if (pasajerosTotal <= trayecto.capacity) {
                auxVis[index]++; 
            }
            if(pasajerosTotal >= trayecto.capacity) {
                limiteCapacidad = true;
            }
        } else {
         auxVis[index]++;
        }

        if (visitas.discount) {
            if (visitas.discount.typeDiscount === TypeDiscount.fix) {
                if (tot < visitas.discount.value) {
                    totDiscount = tot
                } else {
                    totDiscount = visitas.discount.value
                }
            } else if (visitas.discount.typeDiscount === TypeDiscount.percentage) {
                totDiscount = tot * visitas.discount.value / 100
            }
        }
        return {
            auxVis:
                {
                    names, 
                    indices, 
                    vis: auxVis,
                    price: auxPrice, 
                    priceNet: auxPriceNet,
                    base: base, 
                    total: round(tot), 
                    totalDiscount: round(totDiscount), 
                    totalNet: round(totNet),
                    extras: extras, 
                    extrasEdit: visitas.extrasEdit, 
                    disabledButtons: disabledButtonsAux,
                    discount: visitas.discount
                },
                limite:limiteCapacidad
        }
    } else {
        return null;
    }
}

export const quitarVisitanteGlobal = (visitas: Vis, trayecto: Stretch | BasePacket, index: number, prices: Price[]) => {
    if(trayecto && index && visitas && prices) {
        const price = prices[index];
        const precioBase = price.price;
        const precio = precioBase + precioBase * trayecto.tax.value;
        const precioNet = price.priceNet ? (price.priceNet + price.priceNet * trayecto.tax.value) : undefined
        const names = visitas.names.slice()
        const indices = visitas.indices
        const extras = visitas.extras.slice();
        let auxVis = visitas.vis.slice();
        let auxPrice = visitas.price.slice();
        let auxPriceNet = visitas.priceNet ? visitas.priceNet.slice() : undefined
        let tot = visitas.total;
        let totDiscount = visitas.totalDiscount;
        let totNet = visitas.totalNet
        let base = visitas.base;
        let disabledButtonsAux = visitas.disabledButtons;

        if (prices[index].passengerCategory.typePrice === 'byGroup') {
            if (visitas.vis[index] > 1) auxVis[index]--;
            else if (visitas.vis[index] === 1) {
                auxVis[index]--;

                if(auxVis[index] > 0 ){
                    tot = auxPrice[index];
                    if(auxPriceNet) totNet = auxPriceNet[index]
                    base = precioBase;
                } else { // Para que cuando sea 0 en la categoria de grupo, el total se reste a lo que cuesta pero no se quede a 0 si hay otras cosas añadidas
                    tot = tot - auxPrice[index]
                    if(totNet && auxPriceNet) totNet = totNet - auxPriceNet[index]
                    base -= precioBase;
                }

                if (auxVis[index]>0) {
                    auxPrice[index] = precio;
                    if(auxPriceNet && precioNet) auxPriceNet[index] = precioNet
                } else {
                    auxPrice[index] = 0;
                    if(auxPriceNet) auxPriceNet[index] = 0
                }
               
            }

        } else {
            if (auxVis[index] > 0) {
                auxVis[index]--;
                auxPrice[index] = precio * auxVis[index];
                if(auxPriceNet && precioNet) auxPriceNet[index] = precioNet * auxVis[index]
            }
            base -= precioBase;
            tot -= precio;
            if(totNet && precioNet) totNet -= precioNet
        }

        let auxIndex: number;
       /* for (auxIndex = 0; auxIndex < auxVis.length; auxIndex++) {
            const auxPrecioBase = prices[auxIndex].price;
            const auxPrecio = auxPrecioBase + auxPrecioBase * trayecto.tax.value;
            console.log('auxPrecio ',auxPrecio)

            if (prices[auxIndex].passengerCategory.typePrice.localeCompare('byGroup') === 0) {
                if (auxVis[auxIndex] >= 1) {
                    
                    //base = base - auxPrecioBase;
                    //tot = tot - auxPrecio;
                }
            } else {
                console.log('por aqui')
                //base = base - auxVis[auxIndex] * auxPrecioBase;
                //tot = tot - auxVis[auxIndex] * auxPrecio;
            }
        } */

        if(tot < 0){
            tot = 0.00;
        }
        if(totNet < 0) totNet = 0.00
        if (tot === 0) {
            disabledButtonsAux = false;
        }
        
        if (visitas.discount) {
            if (visitas.discount.typeDiscount === TypeDiscount.fix) {
                if (tot < visitas.discount.value) {
                    totDiscount = tot
                } else {
                    totDiscount = visitas.discount.value
                }
            } else if (visitas.discount.typeDiscount === TypeDiscount.percentage) {
                totDiscount = tot * visitas.discount.value / 100
            }
        }
        
        return { 
            indices, 
            names: names, 
            vis: auxVis, 
            price: auxPrice,
            priceNet: auxPriceNet, 
            base: base, 
            total: tot, 
            totalDiscount: totDiscount, 
            totalNet: totNet,
            extras: extras, 
            extrasEdit: visitas.extrasEdit,
            disabledButtons: disabledButtonsAux ,
            discount: visitas.discount
        };
    } else {
        return null;
    }
}

export const addExtraGlobal = (
    visitas: Vis,
    trayecto: Stretch | CollaboratorStretch | BasePacket,
    index: number,
) => {

    if(trayecto && index && visitas) {
        const names = visitas.names;
        const extras = visitas.extras.slice();
        const price = trayecto.extras[index].price;
        const precioBase = price;
        const precio = precioBase + precioBase * visitas.extrasEdit.taxes[index];
        let auxVis = visitas.vis.slice();
        let auxPrice = visitas.price.slice();
        let base = visitas.base;
        let tot = visitas.total;
        let totNet = visitas.totalNet;
        let totDiscount = visitas.totalDiscount
        let disabledButtonsAux = visitas.disabledButtons;
        const indices = visitas.indices

        const auxExtras = visitas.extrasEdit
        const reducer = (accumulator, curr) => accumulator + curr;
        const pasajerosTotal = auxVis.reduce(reducer) + 1
        
        auxExtras.prices[index] += round(precio);
        base += precioBase;
        tot += precio;
        if (visitas.discount) {
            if (visitas.discount.typeDiscount === TypeDiscount.fix) {
                if (tot < visitas.discount.value) {
                    totDiscount = tot
                } else {
                    totDiscount = visitas.discount.value
                }
            } else if (visitas.discount.typeDiscount === TypeDiscount.percentage) {
                totDiscount = tot * visitas.discount.value / 100
            }
        }
        /*                     auxQuantityExtras.push(0)
                    auxPriceExtras.push(0.00)
                    auxTaxExtras.push(extra.tax.value)
                    auxNamesExtras.push(extra.name)
        */
        auxExtras.quantityExtras[index]++;
        return {
            auxVis: {
                names: names,
                indices,
                vis: auxVis,
                price: auxPrice,
                base: base,
                total: round(tot),
                totalNet: round(totNet),
                totalDiscount: round(totDiscount),
                extras: extras,
                extrasEdit: auxExtras,
                disabledButtons: disabledButtonsAux,
                discount: visitas.discount
            }, 
            limite: false
        };
    } else {
        return null;
    }
}

export const quitarExtraGlobal = (
    visitas: Vis,
    trayecto: Stretch | CollaboratorStretch | BasePacket,
    index: number,
) => {
    if(trayecto && index && visitas) {
        const names = visitas.names;
        const extras = visitas.extras.slice();
        const price = trayecto.extras[index].price;
        const precioBase = price;
        const precio = precioBase + precioBase * visitas.extrasEdit.taxes[index];
        let auxVis = visitas.vis.slice();
        let auxPrice = visitas.price.slice();
        let base = visitas.base;
        let tot = visitas.total;
        let totNet= visitas.totalNet;
        let totDiscount = visitas.totalDiscount;
        let disabledButtonsAux = visitas.disabledButtons;
        const indices = visitas.indices

        const auxExtras = visitas.extrasEdit
        const reducer = (accumulator, curr) => accumulator + curr;
        const pasajerosTotal = auxVis.reduce(reducer) + 1
        
        auxExtras.prices[index] -= round(precio);
        base -= precioBase;
        tot -= precio;
        /*                     auxQuantityExtras.push(0)
                    auxPriceExtras.push(0.00)
                    auxTaxExtras.push(extra.tax.value)
                    auxNamesExtras.push(extra.name)
        */
        
        if (visitas.discount) {
            if (visitas.discount.typeDiscount === TypeDiscount.fix) {
                if (tot < visitas.discount.value) {
                    totDiscount = tot
                } else {
                    totDiscount = visitas.discount.value
                }
            } else if (visitas.discount.typeDiscount === TypeDiscount.percentage) {
                totDiscount = tot * visitas.discount.value / 100
            }
        }
        
        auxExtras.quantityExtras[index]--; 
        return {
            auxVis: {
                names: names,
                indices,
                vis: auxVis,
                price: auxPrice,
                base: base,
                total: round(tot),
                totalNet: round(totNet),
                totalDiscount: round(totDiscount),
                extras: extras,
                extrasEdit: auxExtras,
                disabledButtons: disabledButtonsAux,
                discount: visitas.discount
            }, 
            limite: false
        };
    } else {
        return null;
    }
}

export const getIndexForMapeo = ( itemSelected: Stretch | CollaboratorStretch) => {
    const isCollaboratorStretch = !!(itemSelected as CollaboratorStretch).operator_id
    // @ts-ignore
    return !isCollaboratorStretch? itemSelected.id : String(itemSelected.id) + '_' + String((itemSelected as CollaboratorStretch).operator_id)
}

export const checkDisabledDay = (disabledDays: IDisabledDay[], day: Date) => {
    if (!disabledDays) {
        return false
    }

    return disabledDays
        .findIndex(disableDay =>
            disableDay.year === day.getFullYear() &&
            disableDay.month === day.getMonth() &&
            disableDay.day === day.getDate()
        ) !== -1
}

// devuelve true si hay al menos un pasajero añadido en visitas.vis
export const visOK = (visitas) => {
    const vis = visitas.vis
    let visOK = false
    if (!vis) {
        return visOK
    }
    for (let i=0; i<vis.length; i++) {
        if (vis[i] > 0) {
            visOK = true
            break
        }
    }
    return visOK
}

export const lengths = {
    stretch: 16,
    packet: 17,
    collaboratorStretch: 18,
    collaboratorPacket: 19,
    cart: 20
}

export const round = (n, decimals = 2) => {
    return +(n.toFixed(decimals))
    return Number(Math.round(Number(n + 'e' + decimals)) + 'e-' + decimals)
}

export const primeraLetraMayuscula = (cadena: string) => {
    const primerCaracter = cadena.charAt(0).toUpperCase();
    const restoDeLaCadena = cadena.substring(1, cadena.length);
    return primerCaracter.concat(restoDeLaCadena);
};

const randomString = (length: number, chars: string) => {
    let result = '';
    for(let i = length; i > 0; i--) result += chars[Math.floor(Math.random() * chars.length)]
    return result;
}

export const generateTokenticket = (numChars: number) => {
    return randomString(numChars, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ');
}

export const visSanited = (visitas: Vis): Vis => {
    // @ts-ignore
    let visitasSanited: Vis = {}
    Object.assign(visitasSanited, visitas)

    visitas.vis.forEach((item, index) => {
        if (item === 0) {
            visitasSanited.vis.splice(index, 1)
            visitas.price.splice(index, 1)
            visitas.names.splice(index, 1)
        }
    })
    return visitasSanited
} 

export const horasIdaAvailable = (horasIda, dayDisabled) => {
    const horasIdaReduced = horasIda.reduce((previous, horaIda) => {
        const position = dayDisabled.hours.findIndex((iHour) => horaIda.hours === iHour.hours && horaIda.minutes === iHour.minutes)
        if (position === -1) {
            previous.push(horaIda)
        }
        return previous
    }, [])
    return horasIdaReduced
}

export interface IMaxBeforeReserve {
    date: Date
    typeMaxBeforeReserve: string
    valueMaxBeforeReserve: number
    isTramoHora: boolean
}

export const horasIdaAvailableBefore = (horasIda, dataMaxBeforeReserve: IMaxBeforeReserve): IHora[] => {
    const horasIdaReduced = horasIda.reduce((previous: IHora[], horaIda: IHora) => {
        // miro si esta deshabilitado por dateBeforeMax
        if (dataMaxBeforeReserve) {
            const {date, typeMaxBeforeReserve, valueMaxBeforeReserve, isTramoHora} = dataMaxBeforeReserve
            const isBeforeReserve = DisabledDay.maxBeforeReserve(
              date, typeMaxBeforeReserve, valueMaxBeforeReserve, isTramoHora, horaIda
            )
            if (isBeforeReserve) {
                return previous
            }
        }
        previous.push(horaIda)
        return previous
    }, [])
    return horasIdaReduced
}

export const getPasajerosTotales = (passengerCategories) => {
    let total = 0
    passengerCategories.forEach(c => {
        total = total + c.quantity
    })
    return total
}

export const deepFreeze = (object)  => {
    Object.getOwnPropertyNames(object).forEach(name => {
      const property = object[name]
      // if property is an object, freeze it recursively
      if (property && typeof property === 'object')
        deepFreeze(property)
    })
    // at the end, just return the freezed object
    return Object.freeze(object)
  }