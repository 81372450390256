import {useEffect, useState} from 'react'
import {Marketing} from '../models/AllData'
import {readMarketingMain} from '../Modules/Marketing/Request'

export const useMarketing = (publicToken) => {
  const [marketing, setMarketing] = useState<Marketing>(null)
  const [error, setError] = useState(null)
  
  useEffect(() => {
    readMarketingMain(publicToken).then(value => {
      setMarketing(value)
    }).catch(reason => {
      console.error(reason)
      setError(reason)
    })
  }, [publicToken])
  
  return {marketing: marketing, error}
}