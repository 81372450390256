import {useEffect, useState} from 'react'
import {Request} from '../Modules/Afiliate/Request'

export const useAfiliate = (publicToken, at) => {
  const [a, setA] = useState(null)
  const [error, setError] = useState(null)
  
  useEffect(() => {
    if (publicToken && at) {
      Request.getAfiliate(publicToken, at)
        .then(value => {
          setA(value)
        })
        .catch(reason => {
          console.log('useAfiliate - error: ', reason)
          setError(reason)
        })
    } else {
      setA(null)
    }
  }, [publicToken])
  
  return {afiliate: a, error}
}