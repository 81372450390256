import React, { useState } from 'react'
import './ItemList.css'

const ItemList = (
  {
    imgUrl,
    title,
    price,
    allPrices,
    description,
    destinationName,
    category,
    tagStr,
    buttonText,
    moreOptionsText,
    styleVariant = "medium", // Valor por defecto
    onClick,
    onImageClick,
  }) => {

  const [showTooltip, setShowTooltip] = useState(false)
  let tooltipTimeout

  const passengerPrices = allPrices
  const handleMouseEnter = () => {
    clearTimeout(tooltipTimeout)
    setShowTooltip(true)
  }
  
  const handleMouseLeave = () => {
    tooltipTimeout = setTimeout(() => {
      setShowTooltip(false)
    }, 500) // 3 segundos
  }
  
  return (
    <article className={`experience-item experience-item-${styleVariant}`}>
      <figure onClick={onImageClick}>
        <img
          src={imgUrl}
          alt={title}
          className="experience-image"
          loading="lazy"
          itemProp="image"
        />
      </figure>
      <div className="experience-content">
        <h3 itemProp="name" className="experience-title">{title}</h3>
        <p
          itemProp="offers"
          itemScope
          itemType="http://schema.org/Offer"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className="experience-price" itemProp="price">{price}</span>
          <button
            className="info-button"
            onClick={() => setShowTooltip(!showTooltip)}
            aria-label="Mostrar más información sobre precios"
          >
            {/* Ícono de ojo */}
            <label>{moreOptionsText}</label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M12 5c-7.633 0-11 6.16-11 7s3.367 7 11 7 11-6.16 11-7-3.367-7-11-7zm0 12c-2.761 0-5-2.239-5-5s2.239-5 5-5 5 2.239 5 5-2.239 5-5 5zm0-8c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z" />
            </svg>
          </button>
          {showTooltip && (
            <div className={`experience-tooltip ${showTooltip? 'show' : ''}`}>
              <ul>
                {passengerPrices.map((price, index) => (
                  <li key={index}>
                    {price.category}: {price.price}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </p>
        <p className="experience-description" itemProp="description">{description}</p>
        {destinationName && (
          <p className="experience-destination">
            {/*<strong>Destination: </strong>*/}
            <span itemProp="areaServed">{destinationName}</span>
          </p>
        )}
        {category?.name && (
          <p className="experience-category">
           {/* <strong>Category: </strong>*/}{category.name}
          </p>
        )}
        {tagStr && (
          <p className="experience-tag">
           {/* <strong>Tags: </strong>*/}{tagStr}
          </p>
        )}
        <button
          className="reserve-button"
          onClick={onClick}
          itemProp="potentialAction"
          itemScope
          itemType="http://schema.org/Action"
          aria-label={`Reservar experiencia: ${title}`}
        >
          {buttonText}
        </button>
      </div>
    </article>
  )
}

export default ItemList
