import {ReactNode} from 'react'

interface Props {
  header?: ReactNode
  urlImage?: string | { url: string, alt: string, href?: string, size?: string }[][]
}

export const FooterView = ({urlImage, header}: Props) => {
  const defaultProps = {
    //urlImage: 'https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png'
    urlImage: 'https://res.cloudinary.com/marketingpyme/image/upload/v1672387517/logo_ticando_1.png'
  }
  const _urlImage = urlImage || defaultProps.urlImage

  if (typeof _urlImage === 'object' && Array.isArray(_urlImage)) {
    return (
      <footer>
        {header}
        {
          _urlImage.map((line, index) =>
            <div
              key={`footerview_img_${index}`}
              style={{ alignItems: 'center'}}
              className="book-embed-footer container">
              {
                line.map(img => {
                    if (!img.href) {
                      return <img
                        key={`${img.url}`}
                        style={{
                          marginLeft: '5px',
                          }}
                        className={`img-fluid __web-inspector-hide-shortcut__ ${img.size === 'big'? 'item-card-big' : img.size === 'mid'? 'item-card-mid' : ' item-card'}`}
                        src={img.url}
                        alt={img.alt}
                      />
                    } else {
                      return <a
                        key={`${img.url}`}
                        href={img.href}
                        target={'_blank'}
                        style={{
                          marginLeft: '15px'
                        }}
                        className={`img-fluid __web-inspector-hide-shortcut__ ${img.size==='big'? ' item-card-big' : img.size === 'mid'? 'item-card-mid' : ' item-card'}`}
                      >
                        <img
                          className={`img-fluid __web-inspector-hide-shortcut__ ${img.size==='big'? ' item-card-big' : img.size === 'mid'? 'item-card-mid' : ' item-card'}`}
                          style={{
                            marginLeft: '15px'
                          }}
                          src={img.url}
                          alt={img.alt}
                        />
                      </a>
                    }
                  }
                )
              }
            </div>
          )
        }
      </footer>
    )
  }

  return (
    <footer className="book-embed-footer">
      <img src={_urlImage} className="img-fluid __web-inspector-hide-shortcut__" alt="TICANDO"/>
    </footer>
  )
}
