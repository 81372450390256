import React, {MouseEventHandler, useState} from 'react'

import './social_item.css'

interface Props {
  order: number
  item: {
    urlSlug: string
    imgUrl: string
    title: string
    price: string
    priceNumeric: number
    priceCurrency: string
    oldPrice: string
    description: string
    destinationName: string
    allPrices
    moreOptionsText
    tagStr
  }
  buttonText: string
  onBookingClick: MouseEventHandler | undefined
  onImageClick: MouseEventHandler | undefined
}
export const SocialItem = (props: Props) => {
  const {order, item, buttonText, onBookingClick, onImageClick} = props
  const {imgUrl, title, price, priceNumeric, priceCurrency, oldPrice, description, destinationName, allPrices, moreOptionsText, tagStr} = item
  
  const [showTooltip, setShowTooltip] = useState(false)
  let tooltipTimeout

  const passengerPrices = allPrices
  const handleMouseEnter = () => {
    clearTimeout(tooltipTimeout)
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    tooltipTimeout = setTimeout(() => {
      setShowTooltip(false)
    }, 500) // 3 segundos
  }
  
  return (
    <article itemScope itemType="https://schema.org/ListItem">
      <meta itemProp="position" content={order.toString()}/>
      <div className="result-card" itemProp="item" itemScope itemType="https://schema.org/Product">
        <figure onClick={onImageClick}>
          <img className="experience-image"
               src={imgUrl}
               alt={title}
            // className="experience-image"
               loading="lazy"
               itemProp="image"
          />
        </figure>

        <div className="card-content">
          <h2 itemProp="name">{title}</h2>
          <p className="price"
             itemProp="offers"
             itemScope
             itemType="http://schema.org/Offer"
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
          >
            <meta itemProp="price" content={priceNumeric.toString()}/>
            <meta itemProp="priceCurrency" content={priceCurrency}/>
            <link itemProp="availability" href="http://schema.org/InStock" />
            {price} {oldPrice && <span className="old-price">{oldPrice}</span>}
            <button
              className="info-button"
              onClick={() => setShowTooltip(!showTooltip)}
              aria-label="Mostrar más información sobre precios"
            >
              {/* Ícono de ojo */}
              <label>{moreOptionsText}</label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M12 5c-7.633 0-11 6.16-11 7s3.367 7 11 7 11-6.16 11-7-3.367-7-11-7zm0 12c-2.761 0-5-2.239-5-5s2.239-5 5-5 5 2.239 5 5-2.239 5-5 5zm0-8c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"/>
              </svg>
            </button>
            {showTooltip && (
              <div className={`price-tooltip ${showTooltip ? 'show' : ''}`}>
                <ul>
                  {passengerPrices.map((price, index) => (
                    <li key={index}>
                      {price.category}: {price.price}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </p>
          <p className="description" itemProp="description">{description}</p>
          <p className="destination" itemProp="areaServed">{destinationName}</p>
          {tagStr && (
            <p className="tag">
              {/* <strong>Tags: </strong>*/}{tagStr}
            </p>
          )}
          <a itemProp="url" href={item.urlSlug} style={{display: 'none'}}></a>
          <button
            className="cta-btn"
            onClick={onBookingClick}
            itemProp="potentialAction"
            itemScope
            itemType="http://schema.org/Action"
            aria-label={`Reservar experiencia: ${title}`}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </article>
  )
}