import React, {Component} from 'react'
import {Header} from '../components/Header/Header'
import {Footer} from '../components/Footer/Footer'
import {SocialItem} from '../components/Item/SocialItem'
import './main-view.css'
import {Spinner} from 'reactstrap'
import {SelectChangeEvent} from '@mui/material'
import {withTranslation} from "react-i18next"
import ItemList from '../../../components/Recomended/ItemList'
import Carrousel from '../../../components/HorizontalList/Carrousel'

interface Props {
  t?
  loading: boolean
  imgUrl: string
  imgAlt: string
  title: string
  destinationValue: string[]
  destinationOptions: {name: string, value: string}[]
  categoryValue: string[]
  categoryOptions: {name: string, value: string}[]
  tagValue: string[]
  tagOptions: {name: string, value: string}[]
  items: any[]
  onChange: (event: SelectChangeEvent , child?: Object) => void
  keyMaker: (item) => string
  onIconClick
  onBookingClick: (e, item) => void
  onImageClick: (e, item) => void
}

const MainView = withTranslation('global')(class MainViewComponent extends Component<Props, object> {
  
  render() {
    const {t, items, loading, onBookingClick, onImageClick, keyMaker, ...props} = this.props
    
    if (loading) {
      return (
        <header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100%'
            }}
          >
            <Spinner
              style={{
                color: '#007bff',
                width: 50,
                height: 50,
              }}
            />
          </div>
        </header>
      )
    }
    
    let recomended = []
   
    items.forEach(item => {
      if (item.recomended) {
        recomended.push(item)
      }
    })
    
    return (
      <>
        <Header loading={loading} {...props}/>
        {recomended?.length > 0 &&
          <div style={{margin: '10px'}}>
            <Carrousel
              title={<h1 style={{alignSelf: 'self-start'}}><label>
                {t('recommended')}
              </label></h1>}
              items={recomended}
              ItemComponent={ItemList}
              keyMaker={keyMaker}
              t={t}
              ariaLabel="Recommended Experiences"
              onBookingClick={(e, item) => onBookingClick(e, item)}
              onImageClick={(e, item) => onImageClick(e, item)}
            />
          </div>
          }
        {!loading && <div
          className="results-container"
          itemScope
          itemType="https://schema.org/ItemList"
          content="http://schema.org/ItemListOrderDescending"
        >
          {items.map((item, index) => {
            return (
              <SocialItem
                key={keyMaker(item)}
                order={index + 1}
                item={item}
                buttonText={t('bookNow')}
                onBookingClick={(e) => onBookingClick(e, item)}
                onImageClick={(e) => onImageClick(e, item)}
              />
            )
          })}
        </div>}

        {!loading && <Footer hrefSrc={[]}/>}
      </>
    )
  }
})

export default MainView