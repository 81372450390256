import Price from '../../../models/Price'
import {priceWithTaxRounded} from './priceWithTaxRounded'
import {typePriceShow} from './getMainItems'
import Stretch from '../../../models/Stretch'

export const getPricesOrder = ({stretch, typeOrder}: {stretch: Stretch, typeOrder: string}) => {
  const priceOrder = stretch.prices.reduce((previous, current: Price) => {
    if (typeOrder === typePriceShow.orderLow) {
      if (previous.order === null && current.price > 0) {
        const price = priceWithTaxRounded(current.price, stretch.tax.value)
        return {priceToShow: price, passengerCategory: current.passengerCategory.name, order: current.passengerCategory.order}
      }
      if (current.price > 0 && current.passengerCategory.order < previous.order) {
        const price = priceWithTaxRounded(current.price, stretch.tax.value)
        return {priceToShow: price, passengerCategory: current.passengerCategory.name, order: current.passengerCategory.order}
      }
      return previous
    } else if (typeOrder === typePriceShow.orderHigh) {
      if ((!previous.order === null && current.price > 0)) {
        const price = priceWithTaxRounded(current.price, stretch.tax.value)
        return {priceToShow: price, passengerCategory: current.passengerCategory.name, order: current.passengerCategory.order}
      }
      if (current.price > 0 && current.passengerCategory.order > previous.order) {
        const price = priceWithTaxRounded(current.price, stretch.tax.value)
        return {priceToShow: price, passengerCategory: current.passengerCategory.name, order: current.passengerCategory.order}
      }
      return previous
    } else {
      throw new Error('typePriceShow no valid')
    }
  }, {priceToShow: null, passengerCategory: '', order: null})
  
  return priceOrder
}