import React, {useEffect} from 'react';
import {BrowserRouter, Switch, Route, Redirect, useHistory} from 'react-router-dom';
import { PagoIncorrecto } from './components/PagoIncorrecto';
import { PagoCorrecto } from './components/PagoCorrecto';
import { ErrorPage } from './components/ErrorPage';
import {PacketPage} from './Modules/Packet/PacketPage'
import {CartPage} from './Modules/Cart/CartPage'
import {CollaboratorStretchPage} from './Modules/Collaborator/CollaboratorStretchPage'
import { MainPage } from 'Modules/Main/MainPage';
import {StretchPage} from './Modules/Stretch/StretchPage'
import { DefaultPage } from 'components/DefaultPage';
import { ProcesandoPago } from 'Modules/ProcessingPayment/ProcessingPayment';
import { CookiesWarning } from 'components/CookiesWarning';
import { ProcesandoPagoCredomatic } from 'Modules/ProcessingPayment/ProcessingPaymentCredomatic';
import { PagoSinValidar } from 'components/PagoSinValidar';
import queryString from 'query-string'

export const Routes: React.FC = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Root} />
                <Route exact path='/:public' render={(props) => {
                    const matchParamsPublic = String(props.match.params.public)
                    
                    if (props.location.pathname.endsWith('/')) {
                        return <MainPage {...props} social/>
                    }
                    
                    return <Redirect from="/:public" to={`${matchParamsPublic}/main`} />
                }}/>
                {/*<Route exact path='/:public/main' component={MainPage} />*/}
                <Route exact path='/:public/main' render={props => <MainPage {...props} social/>} />
                <Route exact path='/main/:public' render={props => <MainPage {...props} social/>} />
                <Route exact path='/:public/payment/success' component={PagoCorrecto} />
                <Route exact path='/:public/payment/pendingValidation/:code' component={PagoSinValidar} />
                <Route path='/:public/payment/processing/:uuid?/:data?' component={ProcesandoPago} />
                <Route path='/:public/payment/credomatic/:uuid?/' component={ProcesandoPagoCredomatic} />
                <Route exact path='/payment/error' component={PagoIncorrecto} />
                <Route exact path='/:public/cookies' component={CookiesWarning} />
                
                <Route exact path='/:public/cs/:stretch_id/:operator_id' component={CollaboratorStretchPage} />
                <Route exact path='/experience/cs/:stretch_id/:operator_id/:public' component={CollaboratorStretchPage} />
                <Route exact path='/tour/:slug/cs/:stretch_id/:operator_id/:public' component={CollaboratorStretchPage} />
                <Route exact path='/tour/:destination/:category/:slug/cs/:stretch_id/:operator_id/:public' component={CollaboratorStretchPage} />
                <Route exact path='/tour/:destination/:slug/cs/:stretch_id/:operator_id/:public' component={CollaboratorStretchPage} />
                <Route exact path='/tour/:category/:slug/cs/:stretch_id/:operator_id/:public' component={CollaboratorStretchPage} />
                
                <Route exact path='/:public/trayecto/:stretch_id/:operatioLine_id' component={StretchPage} />
                <Route exact path='/experience/:stretch_id/:public' component={StretchPage} />
                <Route exact path='/tour/:slug/:stretch_id/:public' component={StretchPage} />
                <Route exact path='/tour/:destination/:category/:slug/:stretch_id/:public' component={StretchPage} />
                <Route exact path='/tour/:destination/:slug/:stretch_id/:public' component={StretchPage} />
                <Route exact path='/tour/:category/:slug/:stretch_id/:public' component={StretchPage} />
                
                {/*<Route exact path='/:public/trayecto/:stretch_id/:operatioLine_id' component={StretchPageContainer} />*/}
                <Route exact path='/:public/packet/:packet_id/:operationLine_id/' component={PacketPage} />
                <Route exact path='/:public/cp/:packet_id/:operator_id' render={props => <PacketPage {...props} isCollaboratorPacket/>} />
                <Route exact path='/:public/error/:message' component={ErrorPage} />
                <Route exact path='/:public/cart/:data' component={CartPage} />
                <Route exact path='/:public/cart/' component={CartPage} />            
                <Route exact path='/cart/:public/' component={CartPage} />            
                <Route render={() => {
                    return <DefaultPage/> 
                }}/>
            </Switch>
        </BrowserRouter>
    );
};

const Root = () => {
    const history = useHistory()

    const parsed = queryString.parse(window.location.search)
    const at = parsed?.at
    
    useEffect(() => {
        const publicToken = getCookie('X-PT')
        const xAT = getCookie('X-AT')
        const aT = getCookie('A-PT')
        if (publicToken) {
            const _at = at?? xAT?? aT
            return history.push(`/main/${publicToken}${_at? `?at=${_at}`: ''}`)
        }
    }, [])
    return <DefaultPage/>
}

const getCookie = (name) => {
    const cookieString = document.cookie
    const parts = cookieString.split('; ')
    for (const part of parts) {
        const [key, value] = part.split('=')
        if (key === name) {
            return decodeURIComponent(value)
        }
    }
    return null
}