import {PaymentGateway} from "./paymentGateway";
import {getAuthenticationTMT, getChannelByIdTicando, modalError, paymentTMTHandler} from "./Request";
import * as Sentry from "@sentry/react";
import {getChannels} from "Request/Request";
import Moment from 'moment';

export class TrustMyTravel extends PaymentGateway {

  publicToken: string;
  urlBasePost: string;
  namePasarela: string;
  totalAmount: number;
  private _description: string;
  private _pax: number = 0;
  url_post: string;
  url_ok: string;
  url_ko: string;
  name: string;
  surname: string;
  telephone: string;
  email: string;
  channel_id: any;
  additional_info: any;
  money: string;
  dominio: string;
  history: any;
  t: any;
  path: any;
  handlePaymentOk: any;
  handlePaymentError: any;
  uuid: string;
  cart: any;
  error_before_payment: any;
  existsSellerChannel: any;
  isModal: boolean

  constructor(publicToken: string, totalAmount: number, description: string, name: string, surname: string, telephone: string, email: string, channel_id: number, additional_info: string, money: string, dominio: string, history: any, t: any, handlePaymentOk: any, handlePaymentError: any, uuid: any, cart: any, isModal) {
    super();
    this.publicToken = publicToken;
    this.namePasarela = 'TRUSTMYTRAVEL';
    this.totalAmount = totalAmount;
    this.description = description;
    this.name = name;
    this.surname = surname;
    this.telephone = telephone;
    this.email = email;
    this.channel_id = channel_id;
    this.additional_info = additional_info;
    this.money = money;
    this.dominio = dominio;
    this.history = history;
    this.t = t;
    this.handlePaymentOk = handlePaymentOk;
    this.handlePaymentError = handlePaymentError;
    this.uuid = uuid;
    this.cart = cart;
    this.existsSellerChannel = true;
    this.isModal = isModal
  }

  get pax() {
    return this._pax
  }

  get description() {
    return this._description
  }

  set_url_post(url_post): void {
    this.url_post = url_post;
  }

  set_url_ok(url_ok): void {
    this.url_ok = url_ok;
  }

  set_url_ko(url_ko): void {
    this.url_ko = url_ko;
  }

  set description(value) {
    this._description = value
  }

  generateDescriptionPax(): void {

    let stringStretchTickets = ''
    let paxStretchTickets = 0
    let stringCollaboratorStretchTickets = ''
    let paxCollaboratorStretchTickets = 0
    let stringPacketTickets = ''
    let paxPacketTickets = 0
    let stringCollaboratorPacketTickets = ''
    let paxCollaboratorPacketTickets = 0

    this.cart.cartTickets.forEach((item, index, array) => {
      stringStretchTickets += `${item.stretchName}${index < array.length - 1 ? ', ' : ''}`
      paxStretchTickets += this.getPax(item.ticket.lines)
    })

    this.cart.cartCollaboratorTickets.forEach((item, index, array) => {
      stringCollaboratorStretchTickets += `${item.stretchName}${index < array.length - 1 ? ', ' : ''}`
      paxCollaboratorStretchTickets += this.getPax(item.ticket.lines)
    })

    this.cart.cartPacketTickets.forEach((item, index, array) => {
      stringPacketTickets += `${item.packetTicket.packet.name}${index < array.length - 1 ? ', ' : ''}`
      paxPacketTickets += this.getPax(item.packetTicket.lines)
    })

    this.cart.cartCollaboratorPacketTickets.forEach((item, index, array) => {
      stringCollaboratorPacketTickets += `${item.packetTicket.packet.name}${index < array.length - 1 ? ', ' : ''}`
      paxCollaboratorPacketTickets += this.getPax(item.packetTicket.lines)
    })

    let description = stringStretchTickets

    if (stringCollaboratorStretchTickets !== '') {
      if (stringStretchTickets !== '') {
        //description += '-, '
        description = description + ', ' + stringCollaboratorStretchTickets
      } else {
        description = stringCollaboratorStretchTickets
      }
    }

    if (stringPacketTickets !== '') {
      if (description !== '') {
        //description += '-, '
        description = description + ', ' + stringPacketTickets
      } else {
        description = stringPacketTickets
      }
    }

    if (stringCollaboratorPacketTickets !== '') {
      if (description !== '') {
        description = description + ', ' + stringCollaboratorPacketTickets
      } else {
        description = stringCollaboratorPacketTickets
      }
    }

    const pax = paxStretchTickets + paxCollaboratorStretchTickets + paxPacketTickets + paxCollaboratorPacketTickets

    this._description = description
    this._pax = pax
  }

  getPax(lines: any[]): number {
    let temp = 0
    lines.forEach(value => {
      temp += value.quantity
    })
    return temp
  }

  async pay(): Promise<void> {
    try {

      this.generateDescriptionPax()

      if (this.channel_id === 0) {  // Quiere decir que antes habia otra pasarela y se ha cambiado justo antes de clicar en reservar. Hacer peticion para obtener el channel_id
        try {
          let channels = await getChannels(this.publicToken)
          if (channels.msg) {
            this.channel_id = 0 // Valor arbitrario si la pasarela no es TMT
          } else {
            let channel = channels.find(c => this.money === c.currencies);
            this.channel_id = channel.id
          }
        } catch (e) {
          Sentry.captureException(new Error("TrustMyTravel - getChannels"));
          this.history.push(`/${this.publicToken}/error/Ha ocurrido un error - mountTrustMyTravelBody-getChannels`)
        }
      }

      const res: any = await this.mountBody()
      let body = res.body;
      let path = res.path;
      if (body && path) paymentTMTHandler(body, this.url_post, this.url_ok, this.additional_info, this.history, this.publicToken, this.money, this.dominio, path, this.t, this.handlePaymentOk, this.handlePaymentError, this.cart, this.isModal)
      if (res.code === 500) modalError(res.msg)
    } catch (e) {
      Sentry.captureException(new Error("TrustMyTravel - mountTrustMyTravelBody-paymentTMTHandler"));
      this.history.push(`/${this.publicToken}/error/Ha ocurrido un error - mountTrustMyTravelBody-paymentTMTHandler`)
    }
  }

  async mountBody() {

    const total = Math.round(this.totalAmount * 100);
    const description = this._description;
    const nombre = this.name;
    const apellidos = this.surname;
    const telefono = this.telephone;
    const email = this.email;
    let channel_id = this.channel_id;
    const money = this.money
    const publicToken = this.publicToken
    const uuid = this.uuid
    const pax = this.pax
    // const date = Date.now()
    // const dateFormat = Moment(date).format('YYYY-MM-DD')
    const cart = this.cart
    const cartCollaboratorTickets = cart.cartCollaboratorTickets;
    const cartCollaboratorPacketTickets = cart.cartCollaboratorPacketTickets
    const cartTickets = cart.cartTickets
    const cartPacketTickets = cart.cartPacketTickets
    let totalExtrasCart = 0 // total extras que no comisiona el vendedor y que paga al proveedor
    let totalSeller = 0 // Total que le corresponde al vendedor

    let allocations = []

    for (let i = 0; i < cartTickets.length; i++) {// Estos son los tickets de experiencias propias, se sumará el total de todas ellas
      totalSeller = totalSeller + cartTickets[i].ticket.total
    }

    for (let i = 0; i < cartPacketTickets.length; i++) {
      const ticket = cartPacketTickets[i]
      const lines = ticket.packetTicket.lines

      if (ticket.collaboratorTickets.length === 0) {
        totalSeller = totalSeller + ticket.packetTicket.total
      } else if (ticket.collaboratorTickets.length > 0) {
        totalSeller = totalSeller + ticket.packetTicket.total
        // Por cada servicio en colaboración en el paquete

        for (let j = 0; j < ticket.packetTicket.collaboratorTickets.length; j++) {
          const collaboratorTicket = ticket.packetTicket.collaboratorTickets[j]
          let prices = collaboratorTicket.stretch.prices
          console.log('prices ', prices)
          let totalOperator = 0
          for (let k = 0; k < lines.length; k++) {
            const line = lines[k]
            console.log('line ', line)
            let categoryName = line.passengerCategoryName
            let priceCategory = prices.filter(price => price.passengerCategory.name.toLowerCase() === categoryName.toLowerCase())
            totalOperator = totalOperator + (priceCategory[0].packetPriceNet + priceCategory[0].packetPriceNet * collaboratorTicket.stretch.tax.value) * line.quantity
            console.log('totalOperator ', totalOperator)
          }
          totalSeller = totalSeller - (+(totalOperator.toFixed(2)))

          const operator_id = collaboratorTicket.operator_id
          const res = await getChannelByIdTicando(publicToken, operator_id)
          if (res.statusCode && res.statusCode === 500) this.error_before_payment = true
          const channel_id = res.channel_id
          const currency = res.currency
          let allocation = {
            channels: +channel_id,
            currencies: currency,
            operator: 'flat',
            total: (+totalOperator.toFixed(2)) * 100
          }
          allocations.push(allocation)
        }
      }
    }

    for (let i = 0; i < cartCollaboratorTickets.length; i++) {
      const collaboratorTicket = cartCollaboratorTickets[i]
      const operator_id = collaboratorTicket.ticket.operator_id
      // if(operator_id !== 122) {
      const res = await getChannelByIdTicando(publicToken, operator_id)
      if (res.statusCode && res.statusCode === 500) this.error_before_payment = true
      const channel_id = res.channel_id
      const currency = res.currency
      
      const totalExtras = collaboratorTicket.ticket.extraLines?.reduce((prev, curr) => {
        return prev + curr.total
      }, 0) || 0
      
      totalExtrasCart += totalExtras
      
      const totalProvider = (collaboratorTicket.ticket.totalNet + totalExtras) * 100
      
      let allocation = {
        channels: +channel_id,
        currencies: currency,
        operator: 'flat',
        total: totalProvider
      }
      allocations.push(allocation)
      totalSeller = totalSeller + (collaboratorTicket.ticket.total - collaboratorTicket.ticket.totalNet)
    }

    let seller_channel_id = await getChannelByIdTicando(publicToken, cart.client.id)
    if (seller_channel_id.statusCode && seller_channel_id.statusCode === 500) {
      this.existsSellerChannel = false;
      this.error_before_payment = true
    }
    
    const LIVE_PAY_COMMISIONS = process.env.REACT_LIVE_PAY_COMMISIONS
    
    console.log('LIVE_PAY_COMMISIONS: ', LIVE_PAY_COMMISIONS)
    
    let commissionSeller = (0.049 + ((seller_channel_id.additionalCommission) ? seller_channel_id.additionalCommission / 100 : 0))

    if (LIVE_PAY_COMMISIONS) {
      commissionSeller += 0.04
    }
    
    // Se resta 
    // totalSeller = totalSeller - (+(totalOperator.toFixed(2)))
    console.log('totalSeller 1 ', totalSeller)
    
    const totalComisionSeller = (total / 100) * commissionSeller
    
    let totalToSeller = Math.round((totalSeller - totalComisionSeller - totalExtrasCart) * 100)
    console.log('totalToSeller ', totalToSeller)
    if (totalToSeller < 0) {
      Sentry.captureException(new Error("Seller commissions higher than total"));
    }

    if (totalSeller > 0) {
      let allocation_seller = {
        channels: +seller_channel_id.channel_id,
        currencies: seller_channel_id.currency,
        operator: 'flat',
        total: totalToSeller
      }
      allocations.push(allocation_seller)
    }

    console.log('existsSeller ', this.existsSellerChannel)
    console.log('errorBeforePayment ', this.error_before_payment)

    // Fecha de uso más tardía

    let dateEnd = Moment(new Date()).format('YYYY-MM-DD')

    cartTickets.forEach(cartTicket => {
      if (Moment(dateEnd).isBefore(new Date(cartTicket.ticket.datebookNTZ))) {
        dateEnd = Moment(new Date(cartTicket.ticket.datebookNTZ)).format('YYYY-MM-DD')
      }
    })

    cartPacketTickets.forEach(cartPacketTicket => {
      for (let num in cartPacketTicket.packetTicket.datesSelected) {
        let dateAux = new Date(cartPacketTicket.packetTicket.datesSelected[num])
        if (Moment(dateEnd).isBefore(dateAux)) {
          dateEnd = Moment(dateAux).format('YYYY-MM-DD')
        }
      }
    })

    cartCollaboratorTickets.forEach(cartCollaboratorTicket => {
      let dateAux = new Date(cartCollaboratorTicket.datebook)
      if (Moment(dateEnd).isBefore(dateAux)) {
        dateEnd = Moment(dateAux).format('YYYY-MM-DD')
      }
    })

    try {
      const auth = await getAuthenticationTMT(channel_id, total, dateEnd, money, publicToken);
      const path = auth.tmt.sites ? auth.tmt.sites[0].path : auth.tmt.default_site.path

      if (!auth || auth.res === 'No existen las credenciales') throw new Error('error recibiendo auth')


      // Validación total de allocations no supere al total de la venta

      let total_aux = 0
      allocations.forEach(allocation => {
        total_aux = total_aux + allocation.total
      })

      if ((total_aux) > total) {
        console.log("TotalAllocation is higher than total.")
        Sentry.captureException(new Error("TrustMyTravel - TotalAllocation is higher than total."))
        return {
          code: 500,
          msg: "TotalAllocation is higher than total."
        }
      }

      if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        let allocation_ticando = {
          channels: 18542,
          currencies: 'EUR',
          operator: 'percent',
          total: 3.92
        }
        allocations.push(allocation_ticando)
      }
      // console.log('allocations ', allocations)
      return {
        path: path,
        body: {
          // Datos requeridos para todas las transacciones
          booking_auth: auth.auth,
          booking_id: "0", // 0 para crear una nueva reserva
          channels: channel_id,
          payee_name: `${nombre} ${apellidos}`,
          payee_email: email,
          payee_country: "CR",
          currencies: money,//'EUR',  // Esta moneda deberá ser la de baseCurrency of channel
          total: total,

          // datos requeridos para nueva reserva
          country: "CR", // Este es el país que en el dashboard sale como country to travel
          firstname: nombre,
          surname: apellidos,
          email: email,
          date: dateEnd,

          // datos de direccion (del que realiza el pago???
          payee_address: " ",
          payee_city: " ",
          payee_postcode: " ",

          // datos de la venta
          reference: uuid,
          description,
          pax,

          // campos adicionales para la división del pago
          allocations: !this.error_before_payment ? allocations : [],
          
          ...LIVE_PAY_COMMISIONS? {charge_channel: this.existsSellerChannel ? +seller_channel_id.channel_id : null} : undefined // Las comisiones de tmt se cargan en el canal del vendedor
        }
      };
    } catch (e) {
      throw e;
    }
  }
}