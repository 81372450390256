import {Box, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import './index.css'
import {JSX} from 'react'

interface Props<P> {
  labelId?: string
  label: string
  name: string
  id: string
  value: any[]
  options: any[]
  multiple?: boolean
  itemOption: (item) => JSX.Element
  onChange: (event: SelectChangeEvent<P>, child?: Object) => void
}

export const SelectInputLabel = <P extends {}>(props: Props<P>) => {

  const {
    // labelId, label, name, id,
    value, options,
    // multiple,
    itemOption,
    // onChange,
    ...p
  } = props

  return (
    <FormControl
      className="select-formcontrol select-input-label"
      /*// style={{...matches? {width: '100%'} : {width: '30%'}}}*/
        >
      <InputLabel id={props.labelId}>{props.label}</InputLabel>
      <Select
        // @ts-ignore
        value={value}
        {...p}
        renderValue={(selected) => {
          if (typeof selected === 'object' && Array.isArray(selected)) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {
                  selected.map((value) => (
                    <Chip key={value} label={value} variant="outlined" size="small"/>
                  ))
                }
              </Box>
            )
          }
        }}
      >
        {!itemOption && options.map(item => (
          <MenuItem className="MuiMenuItem" key={item.value} value={item.value}>{item.name}</MenuItem>
        ))}
        {itemOption && options.map(item => (
          itemOption(item)
        ))}
      </Select>
    </FormControl>
  )
}