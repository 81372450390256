import React, { Suspense } from 'react';
import './styles/App.css';
import { Routes } from './Routes';
import { BrowserRouter } from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import {HelmetProvider} from 'react-helmet-async'

type Props = {
    basename: string
}  

export const App: React.FC<Props> = ({basename}) => {

    return(
      <HelmetProvider>
        <Suspense fallback=''>
        <BrowserRouter basename={basename}>
        <I18nextProvider i18n={i18next}>          
                <Routes />           
            </I18nextProvider>
        </BrowserRouter>
        </Suspense>
      </HelmetProvider>
    )
}
    
export default App

       


